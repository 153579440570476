@import "@nutkit/tokens/dist/common/web/tokens.scss";
@import "@nutkit/styles/src/base/mixins/breakpoints.scss";

$black-to-transparent: var(--nk-ui-grey-0-color) 0%, transparent 100%;

.panel--with-image-and-gradient-overlay {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid var(--nk-color-grey-default);

  >div {
    position: relative;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, $black-to-transparent);
    background-blend-mode: darken;

    @include nk-tablet {
      background: linear-gradient(90deg, $black-to-transparent);
    }

    @include nk-desktop {
      background: linear-gradient(180deg, $black-to-transparent);
    }
  }

  &--flat {
    &::after {
      /* We use uppercase to bypass SCSS function to use CSS function */
      /* stylelint-disable-next-line function-name-case, color-function-notation */
      background: RGBA(var(--nk-ui-grey-0-color), 0.45);
      background-blend-mode: multiply;

      @include nk-tablet {
        /* stylelint-disable-next-line function-name-case, color-function-notation */
        background: RGBA(var(--nk-ui-grey-0-color), 0.45);
      }

      @include nk-desktop {
        /* stylelint-disable-next-line function-name-case, color-function-notation */
        background: RGBA(var(--nk-ui-grey-0-color), 0.45);
      }
    }
  }
}
